import React, { useContext } from 'react';
import { ActionInterface } from './interface';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';

const Info = ({}: ActionInterface) => {
  const ctx = useContext(GlobalContext);

  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box style={{ width: '100%', marginBottom: '10px' }}>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: isMobile ? '12px' : '16px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '140%',
          color: theme.palette.text.primary
        }}
      >
        <p>
          Olá! Gostaríamos de informá-lo que atualizamos recentemente nossos
          Termos de Uso, Política de Privacidade e Política de Pagamento.
          <br />
          <br />
          As mudanças foram realizadas para melhor refletir nossos serviços e
          garantir uma maior transparência e segurança para você, nosso usuário.
        </p>
        <strong>O que foi atualizado?</strong>
        <br />
        <br />
        <strong>Termos de Uso</strong>: Atualizamos nossos Termos de Uso para
        esclarecer os direitos e responsabilidades de todos os usuários da nossa
        plataforma.
        <br />
        <br />
        <strong>Política de Privacidade</strong>: Revisamos nossa Política de
        Privacidade para detalhar como coletamos, utilizamos e protegemos suas
        informações pessoais.
        <br />
        <br />
        <strong>Política de Pagamento</strong>: Fizemos ajustes na nossa
        Política de Pagamento para garantir clareza sobre os processos de
        cobrança, reembolsos e outros aspectos financeiros.
        <br />
        <br />
        Para continuar utilizando nossos serviços, pedimos que você leia e
        concorde com os novos termos.
        <br />
        <br />
        <strong>Próximos Passos</strong>
        <br />
        <p
          style={{
            marginLeft: '20px'
          }}
        >
          1. Leia atentamente os novos Termos de Uso, Política de Privacidade e
          Política de Pagamento.
          <br />
          2. Role até o final deste documento.
          <br />
          3. Marque a caixa de seleção confirmando que você leu e concorda com
          os novos termos.
          <br />
          4. Clique no botão Aceitar.
        </p>
        Se você tiver alguma dúvida ou precisar de mais informações, entre em
        contato com nosso suporte ao cliente.
        <br />
        <br />
        Agradecemos por continuar utilizando a Clarice.ai.
      </Typography>
    </Box>
  );
};

export default Info;
