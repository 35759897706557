import React, { useContext, useEffect, useState } from 'react';
import { SidebarInterface } from './interface';
import {
  Avatar,
  Box,
  Drawer,
  List,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../utils/context/ThemeContext';
import FullLogo from '../../../../assets/images/jsx-icons/full-logo';
import allRoutes from '../../../../router/function/generateRoute/routes';
import SidebarItemCollapse from './components/SidebarItemCollapse';
import SidebarItem from './components/SidebarItem';
import CreditCard from './components/CreditCard';
import Projects from './components/Projects';
import Brands from './components/Brands';
import useStyles from './style';
import Account from './components/Account';
import { useReduxState } from '../../../../hooks/useReduxState';
import { isBetaUser } from '../../../../utils/function/isBetaUser';

const Sidebar = ({
  sidebarExpanded = false,
  temporary,
  toggle
}: SidebarInterface) => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const classes = useStyles();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const { user }: any = useReduxState();

  const routes = isBetaUser(user, 'brand')
    ? allRoutes
    : allRoutes.filter(route => route.path !== '/brands');

  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(window?.innerHeight || 0);
  }, [window.innerHeight]);

  return (
    <Drawer
      className={classes.root}
      variant={isMobile || temporary ? 'temporary' : 'persistent'}
      anchor="left"
      ModalProps={{
        keepMounted: false
      }}
      open={isMobile || temporary ? sidebarExpanded : true}
      onClose={() => toggle()}
      elevation={0}
      sx={{
        ZIndex: 1203,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '18rem',
          boxSizing: 'border-box',
          // borderRight: "0px",
          borderRight:
            themeMode.mode === 'light'
              ? '1px solid #E7EBF0'
              : '1px solid #3a3541',
          backgroundColor: themeMode.mode === 'light' ? '#fffff' : '#252525',
          color: themeMode.mode === 'light' ? '#3a3541' : '#ffffff',
          '&::-webkit-scrollbar': {
            width: '3px'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: themeMode.mode === 'light' ? '#E5E3E8' : '#757575',
            borderRadius: '20px',
            border: '2px solid transparent'
          }
        },
        maxHeight: '100%',
        overflowY: 'auto'
      }}
    >
      <List
        disablePadding
        sx={{
          marginTop: isMobile ? '1px' : '20px',
          marginBottom: '20px'
        }}
      >
        <Stack
          sx={{
            width: '100%'
          }}
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <FullLogo
            color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
            style={{ width: '120px', marginLeft: '-rem' }}
          />

          <div
            style={{
              marginTop: isMobile ? '0.1rem' : '0.1rem',
              marginBottom: '1rem',
              backgroundColor:
                themeMode.mode === 'light' ? '#f2f2f2' : '#303030',
              width: '220px',
              height: '2px'
            }}
          ></div>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.3rem' }}>
            {routes.map((route, index) =>
              !route.hidden && route.sidebarProps && route.sidebar == 1 ? (
                !route.sidebarHidden && route.child ? (
                  <SidebarItemCollapse
                    item={route}
                    key={index}
                    toggle={() => {
                      if (isMobile) {
                        toggle();
                      }
                      return null;
                    }}
                  />
                ) : (
                  <SidebarItem
                    item={route}
                    key={index}
                    toggle={() => {
                      if (isMobile) {
                        toggle();
                      }
                      return null;
                    }}
                  />
                )
              ) : null
            )}
          </Box>

          <div
            style={{
              marginTop: isMobile ? '0.3rem' : '0.5rem',
              marginBottom: '0.5rem',
              backgroundColor:
                themeMode.mode === 'light' ? '#f2f2f2' : '#303030',
              width: '220px',
              height: '2px'
            }}
          ></div>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.3rem' }}>
            {routes.map((route, index) =>
              !route.hidden && route.sidebarProps && route.sidebar == 2 ? (
                !route.sidebarHidden && route.child ? (
                  <SidebarItemCollapse
                    item={route}
                    key={index}
                    toggle={() => {
                      if (isMobile) {
                        toggle();
                      }
                      return null;
                    }}
                  />
                ) : (
                  <SidebarItem
                    item={route}
                    key={index}
                    toggle={() => {
                      if (isMobile) {
                        toggle();
                      }
                      return null;
                    }}
                  />
                )
              ) : null
            )}
          </Box>
        </Stack>
      </List>
      <Box
        sx={{
          position: height < 730 ? 'relative' : 'absolute',
          // backgroundColor: themeMode.mode === 'light' ? `#fff` : '#303030',
          bottom: isMobile
            ? '0.5rem'
            : {
                xs: '0.5rem',
                sm: '0.5rem',
                md: '1.0rem',
                lg: '1.0rem',
                xl: '1.0rem'
              },
          left: height < 730 ? '0rem' : '2.5rem',
          marginLeft: height < 730 ? '2.5rem' : '0rem'
        }}
      >
        <Projects
          toggle={() => {
            if (isMobile) {
              toggle();
            }
            return null;
          }}
        />

        {isBetaUser(user, 'brand') && (
          <Brands
            toggle={() => {
              if (isMobile) {
                toggle();
              }

              return null;
            }}
          />
        )}

        {![
          'Assinatura Mensal | Profissional',
          'Assinatura Anual | Profissional',
          'Assinatura Mensal | Business',
          'Assinatura Anual | Business'
        ].includes(user?.options?.plan?.type) && (
          <>
            <CreditCard
              percentage={20}
              toggle={() => {
                if (isMobile) {
                  toggle();
                }
                return null;
              }}
            />
          </>
        )}

        <Account
          toggle={() => {
            if (isMobile) {
              toggle();
            }
            return null;
          }}
        />
      </Box>
    </Drawer>
  );
};

export default Sidebar;
