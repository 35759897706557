import React, { useContext } from 'react';
import { RootInterface } from './interface';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';
import useStyles from './style';
import ModalCustom from '../../../ModalCustom';
import { useReduxState } from '../../../../../../../hooks/useReduxState';

const Root = ({ children }: RootInterface) => {
  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const ctx = useContext(GlobalContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const { user }: any = useReduxState();

  const open = user?.options?.acceptedTerms;

  const toggle = () => {};

  return (
    <ModalCustom
      open={!open}
      toggle={() => {
        toggle();
      }}
      containerClass={classes.container}
    >
      <Box
        className={classes.box}
        style={{
          // height: isMobile ? '600px' : '100%',
          maxHeight: isMobile ? '90vh' : '100%',
          width: isMobile ? '100%' : '600px',
          padding: isMobile ? '16px' : '24px',
          gap: '24px',
          touchAction: 'auto'
        }}
      >
        {children}
      </Box>
    </ModalCustom>
  );
};

export default Root;
