import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback
} from 'react';
import {
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  Modal,
  Checkbox,
  Button,
  FormControlLabel
} from '@mui/material';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';
import ModalCustom from '../ModalCustom';
import useStyles from './style';
import Root from './components/Root';
import Title from './components/Title';
import Info from './components/Info';
import Action from './components/Action';
import { styled } from '@mui/system';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { updateAcceptedTerms } from '../../../../../stories/actions/user';
import { useLocation } from 'react-router-dom';

type ModalAcceptedTermsProps = {};

const ScrollableDiv = styled('div')({
  height: '250px',
  overflowY: 'auto',
  marginBottom: '10px',
  paddingRight: '15px',
  '&::-webkit-scrollbar': {
    width: '5px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: `#e3e3e3`,
    borderRadius: '20px',
    border: '3px solid transparent'
  }
});

const ModalAcceptedTerms = ({}: ModalAcceptedTermsProps) => {
  const classes = useStyles();

  const themeMode = useContext(ThemeContext);

  const theme = useTheme();

  const ctx = useContext(GlobalContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const dispatch = useAppDispatch();

  const [checked, setChecked] = useState(false);
  const [scrollEnd, setScrollEnd] = useState(false);

  const handleScroll = useCallback(event => {
    const element = event.target;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight - 1) {
      setScrollEnd(true);
    }
  }, []);

  const handleCheckboxChange = event => {
    setChecked(event.target.checked);
  };

  const handleAccept = async () => {
    dispatch(updateAcceptedTerms(true));
  };

  const location: any = useLocation();
  const pathLocation = location.pathname.split('/');

  if (pathLocation[1] === 'plans') {
    return null;
  }

  return (
    <Root>
      <Title />

      <ScrollableDiv onScroll={handleScroll}>
        <Info />
      </ScrollableDiv>

      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheckboxChange}
            disabled={!scrollEnd}
          />
        }
        label={
          <span style={{ fontSize: '14px' }}>
            Li e aceito os{' '}
            <a
              href="https://clarice.ai/termos"
              target="_blank"
              rel="noreferrer"
            >
              termos de uso
            </a>
            <span style={{ marginRight: '3px' }}>,</span>
            <a
              href="https://clarice.ai/privacidade"
              target="_blank"
              rel="noreferrer"
            >
              política de privacidade
            </a>
            <span style={{ marginLeft: '3px', marginRight: '3px' }}>e</span>
            <a
              href="https://clarice.ai/reembolsos"
              target="_blank"
              rel="noreferrer"
            >
              política de pagamento
            </a>
          </span>
        }
      />

      <Action disabled={!checked} handleAccept={handleAccept} />
    </Root>
  );
};

export default ModalAcceptedTerms;
