export const STATE_TEMPLATE = (themeMode: any) => {
  const baseURL = `/icons/template/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/`;

  return {
    templates: [
      {
        id: 'content-summarizer',
        // title: 'Resumir Conteúdo',
        title: 'Resumidor de Conteúdo',
        // description: 'Obtenha os pontos principais de um conteúdo.',
        description: 'Obtenha um resumo com os pontos-chave de um texto.',
        type: ['Ferramentas'],
        redirect: 'content-summarizer',
        imageUrl: baseURL + 'pencil.svg',
        fields: {
          content: {
            label: 'Conteúdo',
            placeholder: 'Conteúdo',
            rows: 10
          },
          count: {
            label: 'Tamanho',
            placeholder: 'Para quantas palavras deseja resumir?',
            options: [
              { value: '50', description: '50 palavras' },
              { value: '100', description: '100 palavras' },
              { value: '200', description: '200 palavras' },
              { value: '300', description: '300 palavras' },
              { value: '400', description: '400 palavras' }
            ],
            required: true
          }
        }
      },
      {
        id: 'facebook-ads-description',
        title: 'Anúncio para Facebook',
        description:
          'Crie anúncios para o Facebook que estimulem seus clientes a clicarem e comprarem.',
        type: ['Social Media', 'Ads', 'Marketing'],
        redirect: 'facebook-ads-description',
        imageUrl: baseURL + 'facebook.svg',
        fields: {
          name: {
            label: 'Nome da Empresa ou Produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Conte sobre seu produto ou serviço',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          audience: {
            label: 'Qual é o seu público-alvo?',
            placeholder: 'Escritores'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        }
      },
      {
        id: 'subtitle-instagram',
        title: 'Legenda para Instagram',
        description:
          'Escreva legendas cativantes para suas postagens no instagram.',
        type: ['Marketing', 'Social Media'],
        redirect: 'subtitle-instagram',
        imageUrl: baseURL + 'instagram.svg',
        fields: {
          description: {
            label: 'Sobre o que é sua postagem?',
            placeholder: 'Linha de rações para Pets',
            rows: 4,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        }
      },
      {
        id: 'blog-post-intro',
        title: 'Introdução de Blog Post',
        description:
          'Supere o bloqueio da página em branco deixando a Clarice.ai escrever seu parágrafo de abertura.',
        type: ['Blog', 'SEO'],
        redirect: 'blog-post',
        imageUrl: baseURL + 'pencil-edit.svg',
        fields: {
          title: {
            label: 'Título',
            placeholder:
              'Cinco novos modelos de carros elétricos que serão lançados no próximo ano.',
            required: true
          },
          audience: {
            label: 'Audiência',
            placeholder:
              'Pessoas interessadas em veículos automotivos ecológicos'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        }
      },
      {
        id: 'blog-post-conclusion',
        title: 'Conclusão de Blog Post',
        description:
          'Encerre suas postagens de blog com um parágrafo de conclusão envolvente.',
        type: ['Blog', 'SEO'],
        redirect: 'blog-post-conclusion',
        imageUrl: baseURL + 'pencil-edit.svg',
        fields: {
          title: {
            label: 'Título',
            placeholder:
              'Cinco novos modelos de carros elétricos que serão lançados no próximo ano.',
            required: true
          },
          audience: {
            label: 'Audiência',
            placeholder:
              'Pessoas interessadas em veículos automotivos ecológicos'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        }
      },
      {
        id: 'blog-post-outline',
        title: 'Esboço para Blog Post',
        description:
          'Organize suas ideias e conceitos em listas detalhadas e esboços precisos antes de começar a escrever seus artigos de blog.',
        type: ['Blog', 'SEO'],
        redirect: 'blog-post-outline',
        imageUrl: baseURL + 'pencil-edit.svg',
        fields: {
          title: {
            label: 'Título',
            placeholder:
              'Cinco novos modelos de carros elétricos que serão lançados no próximo ano.',
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        }
      },
      {
        id: 'blog-post-topic-ideas',
        title: 'Ideias de tópicos para Blog Post',
        // description:
        // 'Pense em novos tópicos de postagem de blog que envolverão os leitores e terão uma boa classificação no Google.',
        description:
          'Pense em novos tópicos de postagem de blog que envolverão os leitores.',
        type: ['Blog', 'SEO'],
        redirect: 'blog-post-topic-ideas',
        imageUrl: baseURL + 'pencil-edit.svg',
        fields: {
          name: {
            label: 'Nome da empresa/produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Descrição da empresa/produto',
            placeholder: 'Corretor de texto inteligente',
            rows: 4,
            required: true
          },
          audience: {
            label: 'Audiência',
            placeholder: 'Profissionais de marketing'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        }
      },
      {
        id: 'commands',
        title: 'Comandos',
        description:
          'Diga exatamente o que você quer que a Clarice.ai escreva utilzando comandos.',
        type: ['Ferramentas'],
        redirect: 'commands',
        imageUrl: baseURL + 'command.svg',
        fields: {
          description: {
            label: 'Digite seu comando para a Clarice.ai',
            placeholder:
              'Narrar uma história criativa sobre Max viajando para a lua no estilo de Morgan Freeman.',
            rows: 4,
            required: true
          },
          content: {
            label: 'Você tem alguma informação adicional para a Clarice.ai?',
            placeholder:
              'Max era um gato curioso e destemido que gostava de dormir em lugares improváveis.',
            rows: 4
          }
        }
      },
      {
        id: 'company-bio',
        title: 'Biografia da Empresa',
        description:
          'Descreva de forma envolvente a trajetória da sua empresa.',
        type: ['Ecommerce', 'Marketing'],
        redirect: 'company-bio',
        imageUrl: baseURL + 'company-bio.svg',
        fields: {
          name: {
            label: 'Nome da empresa',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Informações da empresa',
            placeholder: 'Sobre o que é sua empresa?',
            rows: 4
          },
          audience: {
            label: 'Audiência',
            placeholder: 'Escritores'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        }
      },
      {
        id: 'content-improver',
        // title: 'Melhore seu Conteúdo',
        // description: 'Reescreva seu conteúdo para deixá-lo mais interessante.',
        title: 'Reescrever Texto',
        description:
          'Reescrever frases e parágrafos sem alterar o significado original do seu texto.',
        type: ['Marketing', 'Social Media'],
        redirect: 'content-improver',
        imageUrl: baseURL + 'content-improver.svg',
        fields: {
          content: {
            label: 'Conteúdo',
            placeholder:
              'Apresentamos soluções inovadoras que simplificam as operações empresariais, permitindo o crescimento rápido e sustentável',
            rows: 6,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        }
      },
      {
        id: 'email-subject',
        title: 'Assuntos de E-mail',
        description: 'Escreva assuntos mais atraentes para o seu email.',
        type: ['E-mail'],
        redirect: 'email-subject',
        imageUrl: baseURL + 'emails.svg',
        fields: {
          name: {
            label: 'Nome da empresa/produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Do que se trata o seu e-mail?',
            placeholder:
              'Apresentamos nosso novo software que utiliza inteligência artificial para criar textos de marketing de alta qualidade.',
            rows: 4,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Inteligente'
          }
        }
      },
      {
        id: 'email-marketing',
        title: 'E-mail Marketing',
        description:
          'Desenvolva campanhas de e-mail marketing que convertem e fidelizam clientes.',
        type: ['E-mail', 'Marketing'],
        redirect: 'email-marketing',
        imageUrl: baseURL + 'emails.svg',
        fields: {
          name: {
            label: 'Assunto e Pré-header',
            placeholder: 'Digite até 50 caracteres para cada...',
            required: true
          },
          description: {
            label: 'Texto do Cabeçalho (Hero)',
            placeholder: 'Digite até 70 caracteres...',
            required: true
          },
          content: {
            label: 'Introdução (Após o “Olá, [NOME]!”)',
            placeholder: 'Digite até 3 linhas de texto...',
            rows: 4,
            required: true
          },
          cta: {
            label: 'Botão CTA',
            placeholder:
              'Digite até quatro palavras, idealmente duas ou três...',
            required: true
          }
        }
      },
      {
        id: 'paragraphs-generator',
        title: 'Gerador de Parágrafos',
        description:
          'Permita que Clarice.ai elabore parágrafos impressionantes para cativar seus leitores.',
        type: ['Blog'],
        redirect: 'paragraphs-generator',
        imageUrl: baseURL + 'notebook-pencil.svg',
        fields: {
          description: {
            label: 'Sobre o que é o seu parágrafo?',
            placeholder: 'Quais são as melhores frutas de cada estação?',
            required: true
          },
          keywords: {
            label: 'Palavras-chave',
            placeholder: 'Maça, laranja'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Informativo'
          }
        }
      },
      {
        id: 'product-description',
        title: 'Descrição de Produto',
        description:
          'A Clarice.ai pode criar descrições mais elaboradas para seus produtos.',
        type: ['Ecommerce', 'Marketing'],
        redirect: 'product-description',
        imageUrl: baseURL + 'target.svg',
        fields: {
          name: {
            label: 'Nome do produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Conte-me sobre o seu produto',
            placeholder:
              'Teste diversas alternativas, incluindo listas de especificações de produtos.',
            rows: 4,
            required: true
          },
          audience: {
            label: 'Audiência',
            placeholder: 'Escritores'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        }
      },
      {
        id: 'subtitle-tiktok',
        title: 'Legenda para TikTok',
        description: 'Escreva legendas cativantes para seus vídeos no TikTok.',
        type: ['Marketing', 'Social Media', 'Videos'],
        redirect: 'subtitle-tiktok',
        imageUrl: baseURL + 'tiktok.svg',
        fields: {
          description: {
            label: 'Sobre o que é o seu vídeo?',
            placeholder: 'Como criar um roteiro de viagens perfeito',
            rows: 4,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'carousel-instagram',
        title: 'Carrossel do Instagram',
        description:
          'O conteúdo slide por slide que você precisa para criar um carrossel do Instagram.',
        type: ['Marketing', 'Social Media'],
        redirect: 'carousel-instagram',
        imageUrl: baseURL + 'instagram.svg',
        fields: {
          description: {
            label: 'Assunto principal',
            placeholder: 'Como criar coelhos',
            rows: 4,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Profissional'
          }
        },
        notReady: false
      },
      {
        id: 'title-youtube',
        title: 'Título para vídeo do Youtube',
        description:
          'Aumente sua visibilidade nas pesquisas com títulos originais para seus vídeos.',
        type: ['Social Media', 'Videos'],
        redirect: 'title-youtube',
        imageUrl: baseURL + 'youtube.svg',
        fields: {
          description: {
            label: 'Qual o assunto do vídeo?',
            placeholder: 'Animais mais fantásticos do planeta',
            rows: 4,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'tags-youtube',
        title: 'Tags para vídeo do Youtube',
        description: 'Gere tags para seus vídeos a partir de palavras-chave.',
        type: ['Social Media', 'Videos'],
        redirect: 'tags-youtube',
        imageUrl: baseURL + 'youtube.svg',
        fields: {
          title: {
            label: 'Tópico ou título do vídeo',
            placeholder: 'Como fazer uma renda extra...',
            rows: 1,
            required: true
          }
        },
        notReady: false
      },
      {
        id: 'youtube-topic-ideas',
        title: 'Ideias de tópicos para vídeo do Youtube',
        description:
          'Encontre temas interessantes para seus vídeos que atraiam os espectadores.',
        type: ['Social Media', 'Videos'],
        redirect: 'youtube-topic-ideas',
        imageUrl: baseURL + 'youtube.svg',
        fields: {
          description: {
            label: 'Qual o assunto do vídeo?',
            placeholder: 'Os mistérios dos elefantes da Tailândia',
            rows: 4,
            required: true
          },
          audience: {
            label: 'Público-alvo',
            placeholder: 'Qual é o seu público-alvo?'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'description-youtube',
        title: 'Descrição para vídeo do Youtube',
        description:
          'Produza descrições originais para vídeos e obtenha uma boa classificação nas pesquisas.',
        type: ['Social Media', 'Videos'],
        redirect: 'description-youtube',
        imageUrl: baseURL + 'youtube.svg',
        fields: {
          title: {
            label: 'Título do vídeo',
            placeholder:
              'Receitas deliciosas para iniciantes na culinária - aprenda a preparar pratos gourmet com ingredientes simples e dicas práticas.',
            rows: 1,
            required: true
          }
        },
        notReady: false
      },
      {
        id: 'youtube-outline',
        title: 'Esboço de Script para vídeo do Youtube',
        description:
          "Crie esboços de script para seus vídeos. Funciona melhor para vídeos estilo 'Lista' e 'Como fazer'.",
        type: ['Social Media', 'Videos'],
        redirect: 'youtube-outline',
        imageUrl: baseURL + 'youtube.svg',
        fields: {
          title: {
            label: 'Título ou tópico do vídeo',
            placeholder: 'As 10 maiores lendas do futebol...',
            rows: 1,
            required: true
          }
        },
        notReady: false
      },
      {
        id: 'youtube-introduction',
        title: 'Gancho e introdução para vídeo do Youtube',
        description:
          'Crie uma introdução que capture a atenção e obrigue os espectadores a assistir até o fim.',
        type: ['Social Media', 'Videos'],
        redirect: 'youtube-introduction',
        imageUrl: baseURL + 'youtube.svg',
        fields: {
          title: {
            label: 'Assunto ou título do vídeo',
            placeholder:
              'As melhores cobranças de falta da história do Futebol...',
            rows: 1,
            required: true
          }
        },
        notReady: false
      },
      {
        id: 'weekly-content-calendar',
        title: 'Calendário Semanal de Conteúdo',
        description:
          'Um calendário de conteúdo semanal com base em palavra-chaves ou um tópico principal no estilo clickbait.',
        type: ['Marketing', 'Social Media'],
        redirect: 'weekly-content-calendar',
        imageUrl: baseURL + 'calendar.svg',
        fields: {
          keywords: {
            label: 'Palavra-chaves ou um tópico principal',
            placeholder: 'As tecnologias do futuro',
            rows: 4,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'commemorative-date',
        title: 'Datas Comemorativas',
        description:
          'Crie mensagens personalizadas para felicitações e datas comemorativas.',
        type: ['Marketing', 'Social Media', 'Ferramentas'],
        redirect: 'commemorative-date',
        imageUrl: baseURL + 'champaine.svg',
        fields: {
          description: {
            label: 'Motivo da comemoração',
            placeholder: 'Dia das mães',
            rows: 4,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Criativo'
          }
        },
        notReady: false
      },
      {
        id: 'explain-to-a-child',
        // title: 'Explique para uma criança',
        // description:
        //   'Resuma tudo de forma simples para que uma criança entenda.',
        title: 'Explique a uma criança',
        description: 'Reescreva o texto para torná-lo mais fácil de entender.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'explain-to-a-child',
        imageUrl: baseURL + 'baby.svg',
        fields: {
          description: {
            label: 'O que deseja explicar?',
            placeholder: 'O que é o espaço sideral?',
            rows: 4,
            required: true
          },
          count: {
            label: 'Grau de Escolaridade',
            placeholder: 'Grau de Escolaridade',
            options: [
              { value: '1º', description: '1º ano' },
              { value: '2º', description: '2º ano' },
              { value: '3º', description: '3º ano' },
              { value: '5º', description: '5º ano' },
              { value: '7º', description: '7º ano' },
              { value: '9º', description: '9º ano' }
            ]
          }
        },
        notReady: false
      },
      {
        id: 'domain-name-generator',
        title: 'Gerador de nome de domínio',
        description: 'Gere ideias de nomes de domínio sobre o seu nicho.',
        type: ['Website', 'Outros'],
        redirect: 'domain-name-generator',
        imageUrl: baseURL + 'global.svg',
        fields: {
          description: {
            label: 'Qual seu nicho?',
            placeholder: 'Empresa de tecnologia',
            rows: 1,
            required: true
          }
        },
        notReady: false
      },
      {
        id: 'citation',
        title: 'Citações Inspiradoras',
        description: 'Obtenha citações inspiradoras de grandes personalidades.',
        type: ['Marketing', 'Outros'],
        redirect: 'citation',
        imageUrl: baseURL + 'quote.svg',
        fields: {
          description: {
            label: 'Tema',
            placeholder: 'O valor da amizade...',
            rows: 1,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'company-slogan',
        title: 'Slogan para Empresas',
        description: 'A frase perfeita que representa e destaca seu negócio.',
        type: ['Marketing', 'Outros'],
        redirect: 'company-slogan',
        imageUrl: baseURL + 'light.svg',
        fields: {
          name: {
            label: 'Nome da empresa',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Descrição da empresa',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'growth-ideas',
        title: 'Ideias de Crescimento',
        description:
          'Táticas de crescimento de alto impacto para ajudar sua empresa a crescer.',
        type: ['Ecommerce', 'Marketing', 'Outros'],
        redirect: 'growth-ideas',
        imageUrl: baseURL + 'rocket.svg',
        fields: {
          name: {
            label: 'Nome da empresa ou produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Conte sobre seu produto ou serviço',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'company-mission',
        title: 'Missão da empresa',
        description:
          'Uma declaração clara e concisa dos objetivos e propósitos da sua empresa.',
        type: ['Marketing', 'Outros'],
        redirect: 'company-mission',
        imageUrl: baseURL + 'company-mission.svg',
        fields: {
          name: {
            label: 'Nome da empresa ou produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Conte sobre a empresa',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          }
        },
        notReady: false
      },
      {
        id: 'company-name',
        title: 'Nome para Empresa ou Produto',
        description:
          'Gere um nome único e vencedor para o seu negócio ou produto.',
        type: ['Ecommerce', 'Marketing', 'Outros'],
        redirect: 'company-name',
        imageUrl: baseURL + 'energy.svg',
        fields: {
          description: {
            label: 'Conte mais sobre seu negócio ou produto',
            placeholder: 'Corretor de texto inteligente',
            rows: 4,
            required: true
          },
          keywords: {
            label: 'Palavras-chave',
            placeholder: 'Corretor, Inteligência Artificial'
          }
        },
        notReady: false
      },
      {
        id: 'company-vision',
        title: 'Visão da Empresa',
        description:
          'Uma visão que atrai as pessoas, clientes e funcionários certos.',
        type: ['Marketing', 'Outros'],
        redirect: 'company-vision',
        imageUrl: baseURL + 'energy.svg',
        fields: {
          name: {
            label: 'Nome da empresa',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Descrição da Empresa',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'facebook-ads-title',
        // title: 'Título de anúncio do Facebook',
        title: 'Título para Facebook Ads',
        description:
          'Crie anúncios do Facebook de alto desempenho para gerar mais conversões.',
        type: ['Marketing', 'Outros'],
        redirect: 'facebook-ads-title',
        imageUrl: baseURL + 'facebook.svg',
        fields: {
          name: {
            label: 'Nome da Empresa ou Produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Conte sobre seu produto ou serviço',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          audience: {
            label: 'Qual é o seu público-alvo?',
            placeholder: 'Escritores'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'seo-homepage',
        title: 'Página Inicial (SEO)',
        description:
          'Escreva títulos otimizados e meta descrições para página inicial que serão bem classificadas no Google.',
        type: ['Ecommerce', 'SEO', 'Website', 'Outros'],
        redirect: 'seo-homepage',
        imageUrl: baseURL + 'seo.svg',
        fields: {
          name: {
            label: 'Nome da empresa ou produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Descrição do produto ou serviço',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          keywords: {
            label: 'Palavras-chave',
            placeholder: 'Corretor, Inteligência Artificial'
          }
        },
        notReady: false
      },
      {
        id: 'seo-service-page',
        title: 'Página de Serviço (SEO)',
        description:
          'Escreva títulos otimizados e meta descrições para página do produto que serão bem classificadas no Google.',
        type: ['Ecommerce', 'SEO', 'Website', 'Outros'],
        redirect: 'seo-service-page',
        imageUrl: baseURL + 'seo.svg',
        fields: {
          name: {
            label: 'Nome da Empresa',
            placeholder: 'Clarice.ai',
            required: true
          },
          theme: {
            label: 'Nome do serviço',
            placeholder: 'Clarice.ai Escritora',
            rows: 4
          },
          description: {
            label: 'Descrição do serviço',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          keywords: {
            label: 'Palavras-chave',
            placeholder: 'Corretor, Inteligência Artificial'
          }
        },
        notReady: false
      },
      {
        id: 'seo-product-page',
        title: 'Página de Produto (SEO)',
        description:
          'Escreva títulos otimizados e meta descrições para página do produto que serão bem classificadas no Google.',
        type: ['Ecommerce', 'SEO', 'Website', 'Outros'],
        redirect: 'seo-product-page',
        imageUrl: baseURL + 'seo.svg',
        fields: {
          name: {
            label: 'Nome da Empresa',
            placeholder: 'Clarice.ai',
            required: true
          },
          theme: {
            label: 'Nome do Produto',
            placeholder: 'Clarice.ai Escritora',
            rows: 4,
            required: true
          },
          description: {
            label: 'Descrição do produto',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          keywords: {
            label: 'Palavras-chave',
            placeholder: 'Corretor, Inteligência Artificial'
          }
        },
        notReady: false
      },
      {
        id: 'seo-blog-post',
        title: 'Postagem de Blog (SEO)',
        description:
          'Escreva títulos otimizados e meta descrições para postagens de blog que serão bem classificadas no Google.',
        type: ['Blog', 'SEO', 'Website', 'Outros'],
        redirect: 'seo-blog-post',
        imageUrl: baseURL + 'seo.svg',
        fields: {
          name: {
            label: 'Nome da Empresa ou Produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          title: {
            label: 'Título da postagem',
            placeholder: 'O que é a Clarice.ai?',
            rows: 4,
            required: true
          },
          description: {
            label: 'Descrição da postagem',
            placeholder: 'A Clarice.ai é um corretor de texto inteligente...',
            rows: 4
          }
          // tone: {
          //   label: 'Tom de Voz',
          //   placeholder: 'Casual e criativo'
          // }
        },
        notReady: false
      },
      {
        id: 'linkedin-ads-title',
        title: 'Título para LinkedIn Ads',
        description:
          'Título de anúncios profissionais e atraentes que farão seu produto ser notado.',
        type: ['Ads', 'Marketing'],
        redirect: 'linkedin-ads-title',
        imageUrl: baseURL + 'linkedin.svg',
        fields: {
          name: {
            label: 'Nome da Empresa ou Produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Conte sobre seu produto ou serviço',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          audience: {
            label: 'Qual é o seu público-alvo?',
            placeholder: 'Escritores'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'linkedin-ads-description',
        title: 'Descrição para LinkedIn Ads',
        description:
          'Descrições de anúncios profissionais e atraentes que farão seu produto ser notado.',
        type: ['Ads', 'Marketing'],
        redirect: 'linkedin-ads-description',
        imageUrl: baseURL + 'linkedin.svg',
        fields: {
          name: {
            label: 'Nome da Empresa ou Produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Conte sobre seu produto ou serviço',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          audience: {
            label: 'Qual é o seu público-alvo?',
            placeholder: 'Escritores'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'linkedin-post',
        title: 'Postagem no LinkedIn',
        description:
          'Postagens inspiradoras no LinkedIn que ajudarão você a criar confiança e autoridade em seu setor.',
        type: ['Ads', 'Social Media', 'Outros'],
        redirect: 'linkedin-post',
        imageUrl: baseURL + 'linkedin.svg',
        fields: {
          description: {
            label: 'Sobre o que é sua postagem?',
            placeholder: 'Como a IA irá impactar as profissões',
            rows: 4,
            required: true
          },
          audience: {
            label: 'Qual é o seu público-alvo?',
            placeholder: 'Escritores'
          },
          cta: {
            label: 'Ação desejada',
            placeholder: 'Marcar um amigo no post'
          }
        },
        notReady: false
      },
      {
        id: 'twitter-post',
        title: 'Postagem para Twitter',
        description: 'Gere os melhores tweets virais e cresça na rede social.',
        type: ['Marketing', 'Social Media'],
        redirect: 'twitter-post',
        imageUrl: baseURL + 'twitter.svg',
        fields: {
          description: {
            label: 'Sobre o que é sua postagem?',
            placeholder:
              'Nova galáxia em formação descoberta por telescópio espacial...',
            rows: 4,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'google-ads-title',
        title: 'Título para Google Ads',
        description:
          'Crie títulos de alta conversão para seus anúncios do Google.',
        type: ['Ads', 'Google', 'Marketing'],
        redirect: 'google-ads-title',
        imageUrl: baseURL + 'google.svg',
        fields: {
          name: {
            label: 'Nome da Empresa ou Produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Conte sobre seu produto ou serviço',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          audience: {
            label: 'Qual é o seu público-alvo?',
            placeholder: 'Escritores'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'google-ads-description',
        title: 'Descrição para Google Ads',
        description:
          'Crie descrições de alta conversão para seus anúncios do Google.',
        type: ['Ads', 'Google', 'Marketing'],
        redirect: 'google-ads-description',
        imageUrl: baseURL + 'google.svg',
        fields: {
          name: {
            label: 'Nome da Empresa ou Produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Conte sobre seu produto ou serviço',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          audience: {
            label: 'Qual é o seu público-alvo?',
            placeholder: 'Escritores'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'resources-for-benefits',
        title: 'Recursos para Benefícios',
        description:
          'Torne os recursos do seu produto em benefícios que motivam a ação.',
        type: ['Frameworks', 'Marketing'],
        redirect: 'resources-for-benefits',
        imageUrl: baseURL + 'cup.svg',
        fields: {
          description: {
            label: 'Descrição do Produto',
            placeholder:
              'Nós tornamos mais simples para as agências automatizarem as tarefas cotidianas, permitindo um crescimento acelerado com menos esforço.',
            rows: 4,
            required: true
          }
          // tone: {
          //   label: 'Tom de Voz',
          //   placeholder: 'Casual e criativo'
          // }
        },
        notReady: false
      },
      {
        id: 'image-prompt',
        title: 'Prompt para Imagens',
        description:
          'Obtenha ideias de prompt de imagem para usar com Clarice.ai Arte.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'image-prompt',
        imageUrl: baseURL + 'picture.svg',
        fields: {
          description: {
            label: 'Ideia geral ou tema',
            placeholder: 'Um gato de botas amarelas',
            rows: 4,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Profissional'
          }
        },
        notReady: true
      },
      {
        id: 'personal-biography',
        title: 'Biografia Pessoal',
        description:
          'Crie uma biografia marcante que mostre sua experiência e chame a atenção dos clientes.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'personal-biography',
        imageUrl: baseURL + 'user.svg',
        fields: {
          name: {
            label: 'Qual seu nome?',
            placeholder: 'Felipe',
            required: true
          },
          description: {
            label: 'Informações Pessoais',
            placeholder:
              'Sugestões:\n' +
              '\n' +
              '1. Informações sobre a família (pais, irmãos, cônjuge, filhos, etc.)\n' +
              '2. Educação (escolas, cursos, graduações, etc.)\n' +
              '3. Realizações acadêmicas e/ou profissionais notáveis\n' +
              '4. Ocupações e cargos relevantes (empregos, voluntariado, etc.)\n' +
              '5. Interesses e hobbies\n' +
              '6. Eventos ou experiências de vida significativas (desafios, superações, momentos marcantes, etc.)\n' +
              '7. Contribuições para a comunidade ou sociedade (ações sociais, filantrópicas, etc.)\n' +
              '8. Prêmios, honras ou reconhecimentos recebidos\n' +
              '9. Metas e aspirações futuras',
            rows: 14
          },
          pointsOfView: {
            label: 'Ponto de Vista',
            placeholder: 'Primeira Pessoa ou Terceira Pessoa'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Profissional'
          }
        },
        notReady: false
      },
      {
        id: 'app-notifications',
        title: 'Notificações de Aplicativos e SMS',
        description:
          'Crie notificações que chamem atenção e façam seus usuários voltarem a utilizar seu app ou produto.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'app-notifications',
        imageUrl: baseURL + 'notification.svg',
        fields: {
          description: {
            label: 'Descreva a notificação',
            placeholder:
              'Utilize as funções de lembretes no nosso aplicativo para gerenciar a sua saúde de forma eficiente....',
            rows: 4,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Profissional'
          }
        },
        notReady: false
      },
      {
        id: 'push-notification',
        title: 'Push Notification',
        description:
          'Crie notificações atraentes e mantenha seus assinantes atualizados com novidades e promoções.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'push-notification',
        imageUrl: baseURL + 'notification.svg',
        fields: {
          title: {
            label: 'Título',
            placeholder: 'Digite um título de até 30 caracteres...',
            required: true
          },
          description: {
            label: 'Mensagem',
            placeholder: 'Digite uma mensagem de até 90 caracteres....',
            rows: 4,
            required: true
          }
        },
        notReady: false
      },
      {
        id: 'aida-structure',
        title: 'AIDA Framework',
        description:
          'Utilize o mais eficiente framework de marketing do mundo: Atenção, Interesse, Desejo, Ação.',
        type: ['Frameworks', 'Outros'],
        redirect: 'aida-structure',
        imageUrl: baseURL + 'aida.svg',
        fields: {
          name: {
            label: 'Nome da Empresa ou Produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Descrição do Produto',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Profissional'
          }
        },
        notReady: false
      },
      {
        id: 'pas-structure',
        title: 'PAS Framework',
        description:
          'Um framework poderoso para a criação de copy de marketing.',
        type: ['Frameworks', 'Outros'],
        redirect: 'pas-structure',
        imageUrl: baseURL + 'aida.svg',
        fields: {
          name: {
            label: 'Nome da Empresa ou Produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Descrição do Produto',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Profissional'
          }
        },
        notReady: false
      },
      {
        id: 'faq-generator',
        title: 'Gerador de Perguntas Frequentes',
        description: 'Crie perguntas frequentes para suas postagens e páginas.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'faq-generator',
        imageUrl: baseURL + 'question.svg',
        fields: {
          description: {
            label: 'Assunto Principal',
            placeholder: 'Curso de programação para iniciantes',
            rows: 4,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Profissional'
          }
        },
        notReady: false
      },
      {
        id: 'engaging-questions',
        title: 'Perguntas Envolventes',
        description:
          'Crie perguntas criativas para seu público e aumente o engajamento.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'engaging-questions',
        imageUrl: baseURL + 'question.svg',
        fields: {
          description: {
            label: 'Assunto',
            placeholder: 'Crescimento de adoção de criptomoedas',
            rows: 4,
            required: true
          },
          audience: {
            label: 'Público-alvo',
            placeholder: 'Investidores'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Profissional'
          }
        },
        notReady: false
      },
      // {
      //   id: 'video-sales',
      //   title: 'Mini VSL (Vendas em Vídeo)',
      //   description:
      //     'Tenha um criador de cartas de vendas para vídeos que convertem ao seu dispor.',
      //   type: ['Frameworks', 'Outros'],
      //   redirect: 'video-sales',
      //   imageUrl: baseURL + 'money.svg',
      //   fields: {
      //     name: {
      //       label: 'Qual é o seu nome?',
      //       placeholder: 'Rafael',
      //       rows: 4
      //     },
      //     audience: {
      //       label: 'Público-alvo',
      //       placeholder: 'Investidores de criptomoedas'
      //     },
      //     tone: {
      //       label: 'Tom de Voz',
      //       placeholder: 'Profissional'
      //     }
      //   },
      //   notReady: true
      // },
      {
        id: 'job-interview',
        title: 'Entrevista de Emprego',
        description:
          'Informe o cargo para o qual está contratando e deixe a Clarice.ai fazer as perguntas por você.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'job-interview',
        imageUrl: baseURL + 'handshake.svg',
        fields: {
          description: {
            label: 'Qual a vaga de emprego?',
            placeholder: 'Desenvolvedor',
            rows: 4,
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Profissional'
          }
        },
        notReady: false
      },
      {
        id: 'class-plan',
        title: 'Plano de Aula',
        description:
          'Crie um plano de aula e uma estratégia para ensinar isso.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'class-plan',
        imageUrl: baseURL + 'pencil.svg',
        fields: {
          content: {
            label: 'Conteúdo',
            placeholder: 'Curso de manutenção de aviões',
            rows: 4,
            required: true
          },
          audience: {
            label: 'Público-alvo',
            placeholder: 'Mecânicos profissionais'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Profissional'
          }
        },
        notReady: false
      },
      {
        id: 'perfect-title',
        title: 'Título Perfeito',
        description:
          'Use as técnicas dos melhores redatores globais, este modelo produzirá títulos de alta conversão para seu negócio.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'perfect-title',
        imageUrl: baseURL + 'perfect-title.svg',
        fields: {
          theme: {
            label: 'Tema',
            placeholder: 'Inteligência Artificial',
            required: true
          },
          description: {
            label: 'Assunto ou descrição do produto',
            placeholder:
              'Como a inteligência artificial pode otimizar seu tempo.',
            rows: 4
          },
          audience: {
            label: 'Público-alvo',
            placeholder: 'Escritores'
          }
        },
        notReady: false
      },
      {
        id: 'custom-emails',
        title: 'Cold E-mails Personalizados',
        description:
          'Escreva cold e-mails que irão aumentar bastante sua taxa de respostas.',
        type: ['E-mail'],
        redirect: 'custom-emails',
        imageUrl: baseURL + 'emails.svg',
        fields: {
          name: {
            label: 'Nome da empresa ou produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Conte sobre sua empresa ou seu produto',
            placeholder: 'A Clarice.ai é uma corretor de texto inteligente',
            rows: 4
          },
          content: {
            label: 'Qual o contexto ou objetivo do e-mail?',
            placeholder: 'Agendar uma demonstração da plataforma',
            rows: 2
          },
          cta: {
            label: 'Ação desejada',
            placeholder:
              'o que você espera que o destinatário faça após ler o e-mail'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Inteligente'
          }
        },
        notReady: false
      },
      {
        id: 'google-my-business',
        title: 'Google Meu Negócio',
        description:
          'Maximize sua visibilidade local com um perfil comercial Google bem otimizado.',
        type: ['Ads', 'Google'],
        redirect: 'google-my-business',
        imageUrl: baseURL + 'google.svg',
        fields: {
          location: {
            label: 'Onde está localizada?',
            placeholder: 'São Paulo',
            required: true
          },
          name: {
            label: 'Nome da Empresa ou Produto',
            placeholder: 'Clarice.ai'
          },
          description: {
            label: 'O que vende/oferece?',
            placeholder: 'Plataforma de correção de texto',
            rows: 4
          }
        },
        notReady: false
      },
      {
        id: 'list-creator',
        title: 'Criador de Listas',
        description:
          'Gere listas numeradas envolventes com base em um tópico central.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'list-creator',
        imageUrl: baseURL + 'list.svg',
        fields: {
          theme: {
            label: 'Tema',
            placeholder: 'Tipos de bebidas mais caras do mundo',
            required: true
          },
          count: {
            label: 'Qual a quantidade de itens você deseja gerar?',
            placeholder: 'Quantos parágrafos deseja?',
            options: [
              { value: 1, description: '1' },
              { value: 2, description: '2' },
              { value: 3, description: '3' },
              { value: 4, description: '4' },
              { value: 5, description: '5' },
              { value: 6, description: '6' },
              { value: 7, description: '7' },
              { value: 8, description: '8' },
              { value: 9, description: '9' },
              { value: 10, description: '10' }
            ]
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Inteligente'
          }
        },
        notReady: false
      },
      {
        id: 'analogy-creator',
        title: 'Metaforizador',
        description:
          'Crie analogias exclusivas que tornam seu discurso de vendas mais memorável e envolvente.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'analogy-creator',
        imageUrl: baseURL + 'happy.svg',
        fields: {
          theme: {
            label: 'Sobre o que é a analogia?',
            placeholder: 'Os animais e as casas',
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Inteligente'
          }
        },
        notReady: false
      },
      {
        id: 'content-expander',
        // title: 'Expansor de Frases',
        // description:
        //   'Expanda suas frases de forma criativa, interessante e envolvente.',
        title: 'Expandir Conteúdo',
        description:
          'Deu branco? Escreva a primeira frase e a Clarice.ai continua escrevendo para você.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'content-expander',
        imageUrl: baseURL + 'ia.svg',
        fields: {
          // description: {
          //   label: 'Palavras ou frase para expandir',
          //   placeholder: 'Esses são os motivos para...'
          // },
          description: {
            label: 'Conteúdo para expandir',
            placeholder:
              'A escrita é uma das ferramentas mais poderosas e versáteis de comunicação já criadas pelo homem, oferecendo estabilidade, flexibilidade e eficiência para a troca de informações, além de ser socialmente relevante em diversas culturas e épocas.',
            rows: 4,
            required: true
          },
          count: {
            label: 'Tamanho',
            placeholder: 'Quanto você deseja expandir?',
            options: [
              { value: '50', description: '50 palavras' },
              { value: '100', description: '100 palavras' },
              { value: '200', description: '200 palavras' },
              { value: '300', description: '300 palavras' },
              { value: '400', description: '400 palavras' }
            ]
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Inteligente'
          }
        },
        notReady: false
      },
      {
        id: 'smart-answers',
        title: 'Respostas Inteligentes',
        description: 'Dê respostas inteligentes para perguntas difíceis.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'smart-answers',
        imageUrl: baseURL + 'ia.svg',
        fields: {
          description: {
            label: 'Tópico ou Pergunta',
            placeholder: 'Como criar uma servidor eficiente?',
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Profissional'
          }
        },
        notReady: false
      },
      {
        id: 'creative-story',
        title: 'História Criativa',
        description:
          'Desenvolva histórias criativas que encantem os seus leitores.',
        type: ['Ferramentas', 'Outros'],
        redirect: 'creative-story',
        imageUrl: baseURL + 'camera.svg',
        fields: {
          theme: {
            label: 'Enredo ou tema',
            placeholder:
              'Max era um gato curioso e destemido que gostava de dormir em lugares improváveis...',
            required: true
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Inteligente'
          }
        },
        notReady: false
      },
      {
        id: 'unique-value-propositions',
        title: 'Proposta de Valor Única',
        description:
          'Crie uma declaração clara que descreva o benefício de sua oferta de maneira poderosa.',
        type: ['Ecommerce', 'Marketing', 'Outros'],
        redirect: 'unique-value-propositions',
        imageUrl: baseURL + 'content-improver.svg',
        fields: {
          theme: {
            label: 'Descrição do negócio',
            placeholder: '',
            required: true
          },
          audience: {
            label: 'Público-alvo',
            placeholder: 'Qual é o seu público-alvo?'
          },
          description: {
            label: 'Problema que resolve',
            placeholder:
              'Principais problemas ou dores que seu produto ou serviço resolve'
          },
          content: {
            label: 'Solução oferecida',
            placeholder:
              'Descrição de como seu produto ou serviço resolve os problemas listados'
          }
        },
        notReady: false
      },
      {
        id: 'linkedin-topic-ideas',
        title: 'Ideias de tópicos para postagem no LinkedIn',
        description:
          'Encontre temas interessantes para suas postagens no LinkedIn.',
        type: ['Social Media'],
        redirect: 'linkedin-topic-ideas',
        imageUrl: baseURL + 'linkedin.svg',
        fields: {
          description: {
            label: 'Qual o assunto da sua postagem?',
            placeholder: 'Os mistérios dos elefantes da Tailândia',
            rows: 4,
            required: true
          },
          count: {
            label: 'Qual a quantidade de ideias que você deseja gerar?',
            placeholder: 'Qual a quantidade de ideias que você deseja gerar?',
            options: [
              { value: 1, description: '1' },
              { value: 2, description: '2' },
              { value: 3, description: '3' },
              { value: 4, description: '4' },
              { value: 5, description: '5' },
              { value: 6, description: '6' },
              { value: 7, description: '7' },
              { value: 8, description: '8' },
              { value: 9, description: '9' },
              { value: 10, description: '10' }
            ]
          },
          audience: {
            label: 'Público-alvo',
            placeholder: 'Qual é o seu público-alvo?'
          },
          cta: {
            label: 'Ação desejada',
            placeholder: 'Marcar um amigo no post'
          }
        },
        notReady: false
      },
      {
        id: 'instagram-topic-ideas',
        title: 'Ideias de Reels para Instagram',
        description: 'Encontre temas interessantes para reels no Instagram.',
        type: ['Social Media'],
        redirect: 'instagram-topic-ideas',
        imageUrl: baseURL + 'instagram.svg',
        fields: {
          description: {
            label: 'Quais são os valores da sua marca?',
            placeholder: '',
            rows: 4,
            required: true
          },
          count: {
            label: 'Qual a quantidade de ideias que você deseja gerar?',
            placeholder: 'Qual a quantidade de ideias que você deseja gerar?',
            options: [
              { value: 1, description: '1' },
              { value: 2, description: '2' },
              { value: 3, description: '3' },
              { value: 4, description: '4' },
              { value: 5, description: '5' },
              { value: 6, description: '6' },
              { value: 7, description: '7' },
              { value: 8, description: '8' },
              { value: 9, description: '9' },
              { value: 10, description: '10' }
            ],
            required: true
          },
          audience: {
            label: 'Público-alvo',
            placeholder: 'Qual é o seu público-alvo?'
          },
          cta: {
            label: 'Ação desejada',
            placeholder: 'Marcar um amigo no post'
          }
        },
        notReady: false
      },
      {
        id: 'instagram-ads-description',
        title: 'Anúncio para Instagram',
        description:
          'Gere anúncios para o instagram para fazer com que seus clientes cliquem e comprem.',
        type: ['Social Media', 'Ads', 'Marketing'],
        redirect: 'instagram-ads-description',
        imageUrl: baseURL + 'instagram.svg',
        fields: {
          theme: {
            label: 'Tema',
            placeholder: 'Inteligência Artificial'
          },
          description: {
            label: 'Assunto ou descrição do produto',
            placeholder:
              'Como a inteligência artificial pode ajudar você a escrever melhor.',
            rows: 4,
            required: true
          },
          audience: {
            label: 'Qual é o seu público-alvo?',
            placeholder: 'Escritores'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      },
      {
        id: 'ads-ideas',
        title: 'Ideias de Anúncios',
        description:
          'Receba algumas ideias para anunciar sua marca, produto ou serviço na internet. ',
        type: ['Social Media', 'Ads', 'Marketing'],
        redirect: 'ads-ideas',
        imageUrl: baseURL + 'rocket.svg',
        fields: {
          name: {
            label: 'Nome da Empresa ou Produto',
            placeholder: 'Clarice.ai',
            required: true
          },
          description: {
            label: 'Conte sobre seu produto ou serviço',
            placeholder: 'Corretor de texto inteligente',
            rows: 4
          },
          audience: {
            label: 'Qual é o seu público-alvo?',
            placeholder: 'Escritores'
          },
          tone: {
            label: 'Tom de Voz',
            placeholder: 'Casual e criativo'
          }
        },
        notReady: false
      }
    ]
  };
};
